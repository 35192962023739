var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "card-tile",
        {
          attrs: { "full-width": "", title: "Students" },
          scopedSlots: _vm._u([
            {
              key: "toolbar-actions",
              fn: function() {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.addDialog = true
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v("mdi-account-multiple-plus")
                      ]),
                      _vm._v("Add Students")
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "v-list",
            {
              staticStyle: { "overflow-y": "auto" },
              attrs: { "two-line": "", "min-height": 635, "max-height": 635 }
            },
            [
              _vm._l(_vm.students, function(item, index) {
                return [
                  _c(
                    "v-list-item",
                    { key: index, attrs: { link: "" } },
                    [
                      _c(
                        "v-list-item-avatar",
                        [
                          _c(
                            "v-avatar",
                            [
                              _vm.$_.has(item, "profile_photo_url")
                                ? _c("img", {
                                    attrs: { src: item.profile_photo_url }
                                  })
                                : _c(
                                    "v-icon",
                                    {
                                      staticClass: "primary--text",
                                      attrs: { size: "48" }
                                    },
                                    [_vm._v("mdi-account-circle")]
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-hover", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var hover = ref.hover
                                return [
                                  _c("v-list-item-content", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              ),
                                              _vm.$_.has(item, "order.id")
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-body-2 font-weight-regular"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "| Order # " +
                                                          _vm._s(
                                                            item.order.id
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  "| " +
                                                    _vm._s(item.email) +
                                                    " "
                                                )
                                              ])
                                            ]),
                                            _c(
                                              "v-list-item-subtitle",
                                              [
                                                _vm.$_.has(item, "order.status")
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "mt-1",
                                                        attrs: {
                                                          small: "",
                                                          color: "primary",
                                                          dark: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.order.status
                                                                .replace(
                                                                  "SCREEN",
                                                                  "REPORT"
                                                                )
                                                                .replace(
                                                                  "_",
                                                                  " "
                                                                )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "mt-1",
                                                        attrs: {
                                                          small: "",
                                                          color: "warning",
                                                          dark: ""
                                                        }
                                                      },
                                                      [_vm._v(" PENDING ")]
                                                    )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c("v-spacer"),
                                        _vm.$_.has(item, "order.status")
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: { width: "220px" }
                                              },
                                              [
                                                hover
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: "primary"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: { small: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-account"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v("View")
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                hover
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "mr-3 ml-2",
                                                        attrs: {
                                                          color: "secondary",
                                                          small: ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: { small: "" }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-file-pdf"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v("Print")
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticStyle: { width: "220px" }
                                              },
                                              [
                                                hover
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: "primary"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: { small: "" }
                                                          },
                                                          [_vm._v("mdi-send")]
                                                        ),
                                                        _vm._v("Resend Invite")
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  ),
                  index < _vm.students.length - 1
                    ? _c("v-divider", {
                        key: "divider-" + index,
                        staticClass: "my-2"
                      })
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.addDialog,
            callback: function($$v) {
              _vm.addDialog = $$v
            },
            expression: "addDialog"
          }
        },
        [
          _vm.addDialog
            ? _c("student-add", {
                on: {
                  save: _vm.addStudents,
                  close: function($event) {
                    _vm.addDialog = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }