<template>
    <div>
        <card-tile full-width title="Students">
            <template v-slot:toolbar-actions>
                <v-btn text @click.stop="addDialog = true"
                    ><v-icon class="mr-2">mdi-account-multiple-plus</v-icon>Add
                    Students</v-btn
                >
            </template>
            <v-list
                two-line
                :min-height="635"
                :max-height="635"
                style="overflow-y: auto"
            >
                <template v-for="(item, index) in students">
                    <v-list-item link :key="index">
                        <v-list-item-avatar>
                            <v-avatar>
                                <img
                                    v-if="$_.has(item, 'profile_photo_url')"
                                    :src="item.profile_photo_url"
                                />
                                <v-icon size="48" class="primary--text" v-else
                                    >mdi-account-circle</v-icon
                                >
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-hover v-slot="{ hover }">
                            <v-list-item-content>
                                <div class="d-flex align-center">
                                    <div>
                                        <v-list-item-title>
                                            {{ item.name }}
                                            <span
                                                v-if="$_.has(item, 'order.id')"
                                                class="text-body-2 font-weight-regular"
                                                >| Order # {{ item.order.id }}
                                            </span>
                                            <span
                                                >|
                                                {{ item.email }}
                                            </span>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-chip
                                                v-if="
                                                    $_.has(item, 'order.status')
                                                "
                                                small
                                                color="primary"
                                                dark
                                                class="mt-1"
                                            >
                                                {{
                                                    item.order.status
                                                        .replace(
                                                            "SCREEN",
                                                            "REPORT"
                                                        )
                                                        .replace("_", " ")
                                                }}
                                            </v-chip>
                                            <v-chip
                                                v-else
                                                small
                                                color="warning"
                                                dark
                                                class="mt-1"
                                            >
                                                PENDING
                                            </v-chip>
                                        </v-list-item-subtitle>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div
                                        style="width: 220px"
                                        v-if="$_.has(item, 'order.status')"
                                    >
                                        <v-btn
                                            small
                                            v-if="hover"
                                            color="primary"
                                            ><v-icon small class="mr-2"
                                                >mdi-account</v-icon
                                            >View</v-btn
                                        >
                                        <v-btn
                                            v-if="hover"
                                            color="secondary"
                                            class="mr-3 ml-2"
                                            small
                                            ><v-icon small class="mr-2"
                                                >mdi-file-pdf</v-icon
                                            >Print</v-btn
                                        >
                                    </div>
                                    <div style="width: 220px" v-else>
                                        <v-btn
                                            small
                                            v-if="hover"
                                            color="primary"
                                            ><v-icon small class="mr-2"
                                                >mdi-send</v-icon
                                            >Resend Invite</v-btn
                                        >
                                    </div>
                                </div>
                            </v-list-item-content>
                        </v-hover>
                    </v-list-item>
                    <v-divider
                        class="my-2"
                        :key="`divider-${index}`"
                        v-if="index < students.length - 1"
                    ></v-divider>
                </template>
            </v-list>
        </card-tile>
        <v-dialog v-model="addDialog" max-width="800">
            <student-add
                v-if="addDialog"
                @save="addStudents"
                @close="addDialog = false"
            ></student-add>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: "student-list",
    components: {
        CardTile: () => import("../components/Dashboard/CardTile"),
        StudentAdd: () => import("./StudentAdd"),
    },
    props: {
        students: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            addDialog: false,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        addStudents(students) {
            this.$emit("add-students", students);
            this.addDialog = false;
        },
    },
};
</script>

<style scoped>
</style>